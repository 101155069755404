import * as Yup from 'yup';
import { FIELD_VALIDATION } from '_constants';

export const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(FIELD_VALIDATION.REQUIRED),
  password: Yup.string()
    .trim()
    .min(8, FIELD_VALIDATION.PASSWORD)
    .required(FIELD_VALIDATION.REQUIRED),
  confirm_password: Yup.string()
    .trim()
    .min(8, FIELD_VALIDATION.PASSWORD)
    .required(FIELD_VALIDATION.REQUIRED)
    .oneOf([Yup.ref('password'), null], FIELD_VALIDATION.PASSWORD_MATCH)
});

export const _initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  company: '',
  password: '',
  confirm_password: ''
};
