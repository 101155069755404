import styled from 'styled-components';
import { em } from 'styles';
import { Button } from 'components/_shared/Button';
import { shouldForwardProp } from 'utils';

export const Content = styled.div`
  flex-grow: 1;
  margin: 0 ${em(12)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WelcomeNotification = styled.div`
  text-align: center;
  font-size: 1.2em;
`;

export const StyledButton = styled(Button).withConfig({ shouldForwardProp })`
  justify-content: center;
  width: 100%;
  padding: ${em(8)} ${em(10)};
  font-size: ${em(16)};
  background-color: ${({ isActive }) => (isActive ? '#C7CEFF' : 'inherit')};
`;
