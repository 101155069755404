import React from 'react';

import { Page } from 'components/_shared/Page';
import { useUserActions } from 'state';

import { Content, StyledButton, WelcomeNotification } from './WelcomePage.styles';

export const WelcomePage = ({ ...props }) => {
  const { toggleLogoutModal } = useUserActions();
  return (
    <Page fullHeight {...props}>
      <Content>
        <WelcomeNotification>
          <p>You are not connected to the Grower Adviser system.</p>
          <p>We will contact you to complete account setup via the provided email.</p>
          <StyledButton icon='LogOut' onClick={toggleLogoutModal}>
            Log Out
          </StyledButton>
        </WelcomeNotification>
      </Content>
    </Page>
  );
};
